<template>
  <div>
    <div class="flex flex-row pb-3">
      <div class="w-1/2">
        <el-input
          v-model="querySearch"
          placeholder="Buscar por nombre del servicio o descripción">
          <i slot="prefix" class='mt-2 text-xl bx bx-search' />
        </el-input>
      </div>
    </div>
    <div class="py-2">
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full border-collapse">
          <thead>
            <tr class="px-2 border-b-2">
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"></th>
              <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
                Servicio
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!services.length">
              <td :colspan="5">
                <div class="flex flex-col w-full py-8 text-center">
                  <span>{{ emptyMessage }}</span>
                </div>
              </td>
            </tr>
            <tr
              v-for="service in services"
              :key="service.id"
              class="cursor-pointer hover:bg-gray-100 hover:shadow">
              <td class="p-2 border-t" v-if="!service.businessId" style="min-width: 50px; width: 50px;"></td>
              <td class="p-2 border-t" v-if="service.businessId" style="min-width: 50px; width: 50px;">
                <el-popover
                  placement="right"
                  width="100"
                  trigger="hover">
                  <div class="w-full flex flex-row text-center">
                    <div class="w-1/2">
                      <button
                        title="Editar"
                        class="el-button--primary rounded-full cursor-pointer"
                        @click="onEdit(service)">
                        <i class="bx bx-edit" style="padding: 4px; font-size: 20px;"></i>
                      </button>
                    </div>
                    <div class="w-1/2">
                      <button
                        title="Eliminar"
                        class="el-button--danger rounded-full cursor-pointer"
                        @click="onDelete(service)"
                        :disabled="!service.businessId">
                        <i class="bx bx-trash" style="padding: 4px; font-size: 20px;"></i>
                      </button>
                    </div>
                  </div>
                  <el-button slot="reference" type="text" class="w-full">
                    <button
                      :class="service.businessId
                      ? 'el-button--primary rounded-full cursor-pointer'
                      : 'bg-gray-200 rounded-full'">
                      <i class="text-2xl bx bx-chevron-right"></i>
                    </button>
                  </el-button>
                </el-popover>
              </td>
              <td class="p-2 border-t" style="min-width: 150px;">
                {{ service.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import _Command, { Commands } from '@/components/dialogs/Service/commands';
import { ServiceEnumDict } from '@/constants';
import CategoryService from '@/services/CategoryService';
import _Service from '@/components/dialogs/Category/service';
import _CategoryEnum from '@/components/dialogs/Category/enum';
import { command as _CommandCategory, commands as _CommandsCategory } from '@/components/dialogs/Category/service';

export default {
  name: 'ServicesList',
  mixins: [
    error,
  ],
  props: {
    onCreated: Object,
  },
  data () {
    return {
      querySearch: null,
      services: [],
      loadingServices: false,
      subscription: null,
      categorySubscription: null,
      ServiceEnumDict,
    };
  },
    computed: {
    emptyMessage() {
      return this.querySearch !== null
        ? `No se encontraron servicios que coincidan con "${this.querySearch}"`
        : 'No se han agregado servicios'
    },
  },
  watch: {
    querySearch: _.debounce(async function (querySearch) {
      this.services = await this.loadServices(querySearch);
    }, 500),
    onCreated: _.debounce(async function() {
      this.services = await this.loadServices(this.querySearch);
      this.onCreated = null;
    }, 500),
  },
  async mounted() {
    this.services = await this.loadServices();
  },
  created() {
    this.subscription = _Command.subscribe(this.commands);
    this.categorySubscription = _CommandCategory.subscribe(this.categoryCommands);
  },
  destroyed() {
    this.subscription.unsubscribe();
    this.categorySubscription.unsubscribe();
  },
  methods: {
    commands({ type, payload }) {
      switch (type) {
        case Commands.create:
          this.services = [...this.services, payload];
          break;
        case Commands.update:
          this.services = this.services.map((service) => service.id === payload.id ? payload : service);
          break;
        default:
      }
    },
    categoryCommands({ type, payload }) {
      switch(type) {
        case _CommandsCategory.update:
          this.services = this.services.map((service) => service.id === payload.id ? payload : service);
          break;
        default:
      }
    },
    async loadServices(querySearch) {
      try {
        return await CategoryService.Service.get({ textSearch: querySearch });
      }
      catch(error) {
        this.$toastr.e(this.getErrorMessage(error));
      }
    },
    onEdit(service) {
      _Service.show({ type: _CategoryEnum.Service, value: service });
    },
    onDelete(service) {
      this.$confirm(`¿Está seguro que desea eliminar este servicio?`, {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        await this.remove(service);
      });
    },
    async remove(service) {
      try {
        await CategoryService.Service.delete(service.id);
        const indexOfCurrentService = this.services.findIndex(s => s.id === service.id);
        this.services.splice(indexOfCurrentService, 1);
        this.$toastr.s('Se borro con exito');
      }
      catch (error) {
        this.$toastr.e(error.message || 'Ocurrió un error al eliminar el servicio');
      }
    },
  },
};
</script>

